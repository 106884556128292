import { useCallback } from "react";

export interface WatchedShowDetails {
	Name: string;
	Image: string;
	TotalEpisodes: number;
	timestamp: number;
}

const STORAGE_KEY = "anyanime_watchedShows";
const MAX_SHOWS = 24;

const useWatchedShows = () => {
	const setWatchedShow = useCallback((showDetails: WatchedShowDetails) => {
		if (!showDetails?.Name) {
			return;
		}

		const existingData = localStorage.getItem(STORAGE_KEY);
		const watchedShows: WatchedShowDetails[] = existingData
			? JSON.parse(existingData)
			: [];

		// Remove the show if it already exists and add the new one with the current timestamp
		const updatedShows = [
			...watchedShows.filter(
				(show: WatchedShowDetails) => show.Name !== showDetails.Name
			),
			{ ...showDetails, timestamp: Date.now() },
		];

		updatedShows.sort((a, b) => b.timestamp - a.timestamp);

		// If there are more than MAX_SHOWS, remove the oldest entries
		if (updatedShows.length > MAX_SHOWS) {
			updatedShows.splice(MAX_SHOWS); // Remove oldest entries
		}

		localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedShows));
	}, []);

	const getWatchedShows = useCallback((): WatchedShowDetails[] => {
		const existingData = localStorage.getItem(STORAGE_KEY);
		return existingData ? JSON.parse(existingData) : [];
	}, []);

	return { getWatchedShows, setWatchedShow };
};

export default useWatchedShows;

import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { memo } from "react";

const FootNavMobile = memo(
	({
		scrollToTop,
		typographyStyle,
	}: {
		scrollToTop: () => void;
		typographyStyle: any;
	}) => {
		return (
			<Box sx={{ display: { xs: "block", sm: "none" } }}>
				<Box sx={{ paddingBottom: "1.5rem" }}>
					<Box sx={{ display: "flex", flexWrap: "wrap" }}>
						<Typography sx={{ ...typographyStyle }}>
							AnyAnime does not own or store any of these shows on
							our server, we only linked to the media which is
							hosted on 3rd party services.
						</Typography>
					</Box>
				</Box>
				<Divider
					orientation="horizontal"
					flexItem
					sx={{ borderColor: "#a0a0a050" }}
				/>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						flexDirection: "column",
						paddingBottom: "1.25rem",
						paddingTop: "1.5rem",
						justifyContent: "center",
					}}
				>
					<Stack
						direction="column"
						gap={2}
						sx={{
							mr: 1,
							width: "35vw",
						}}
					>
						<img
							src="https://coverimagecdn.s3.us-west-2.amazonaws.com/logo-no-background.svg"
							alt="AnyAnime"
						/>
						<Typography
							sx={{
								...typographyStyle,
							}}
						>
							All rights reserved
						</Typography>
						<Button
							variant="text"
							startIcon={<ArrowCircleUpIcon />}
							onClick={scrollToTop}
							sx={{ ...typographyStyle }}
						>
							Back to top
						</Button>
					</Stack>
				</Box>
			</Box>
		);
	}
);

const FootNavDesktop = memo(
	({
		scrollToTop,
		typographyStyle,
	}: {
		scrollToTop: () => void;
		typographyStyle: any;
	}) => {
		return (
			<Box
				sx={{
					display: { xs: "none", sm: "flex" },
					flexDirection: "column",
				}}
			>
				<Box sx={{ paddingBottom: "1.5rem" }}>
					<Typography sx={{ ...typographyStyle }}>
						AnyAnime does not own or store any of these shows on our
						server, we only linked to the media which is hosted on
						3rd party services.
					</Typography>
				</Box>
				<Divider
					orientation="horizontal"
					flexItem
					sx={{ borderColor: "#a0a0a050" }}
				/>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						flexDirection: "row",
						paddingBottom: "1.25rem",
						paddingTop: "1.5rem",
						justifyContent: "space-between",
						width: "100%",
					}}
				>
					<Stack
						direction="row"
						gap={1}
						sx={{
							height: "20px",
							width: "50%",
						}}
					>
						<img
							src="https://coverimagecdn.s3.us-west-2.amazonaws.com/logo-no-background.svg"
							alt="AnyAnime"
						/>
						<Divider
							orientation="vertical"
							flexItem
							sx={{ borderColor: "#a0a0a050" }}
						/>
						<Typography
							sx={{
								...typographyStyle,
							}}
						>
							All rights reserved
						</Typography>
					</Stack>
					<Button
						variant="text"
						startIcon={<ArrowCircleUpIcon />}
						onClick={scrollToTop}
						sx={{ ...typographyStyle }}
					>
						Back to top
					</Button>
				</Box>
			</Box>
		);
	}
);

export const FootNav = memo(() => {
	const typographyStyles = {
		color: "#a0a0a0",
		fontSize: "1rem",
		lineHeight: "1.125rem",
		fontWeight: 300,
		textRendering: "auto",
		fontFamily: "Lato,Helvetica Neue,helvetica,sans-serif",
	};
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};
	return (
		<Box
			textAlign="center"
			sx={{
				background: "#141414 linear-gradient(180deg,#0000,#213944)",
				paddingTop: ".75rem",
				boxSizing: "border-box",
				paddingInline: { xs: "20px", sm: "30px", md: "60px" },
				bottom: 0,
			}}
		>
			<FootNavDesktop
				scrollToTop={scrollToTop}
				typographyStyle={typographyStyles}
			/>
			<FootNavMobile
				scrollToTop={scrollToTop}
				typographyStyle={typographyStyles}
			/>
		</Box>
	);
});

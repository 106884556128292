import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

const hideLoader = () => {
	const loader = document.querySelector(".loader-container") as HTMLElement;
	if (loader) {
		loader.style.display = "none";
	}
};

const RootComponent = () => {
	useEffect(() => {
		hideLoader();
	}, []);

	return (
		<React.StrictMode>
			<HelmetProvider>
				<App />
			</HelmetProvider>
		</React.StrictMode>
	);
};

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(<RootComponent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import SearchIcon from "@mui/icons-material/Search";
import { Box, InputAdornment, TextField } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const HeaderSearchBox = memo(() => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [inputValue, setInputValue] = useState("");

	useEffect(() => {
		const query = searchParams.get("query");
		setInputValue(query ?? "");
	}, [searchParams]);

	const onTextChange = (value: string) => {
		setInputValue(value);
		searchParams.set("query", value);
		value
			? navigate(`/search?${searchParams.toString()}`, {
					replace: inputValue.length ? true : false,
			  })
			: navigate("/");
	};

	return (
		<Box
			sx={{
				zIndex: 1400,
				alignSelf: "center",
				width: { xs: "174px", sm: "228px", md: "258px" },
			}}
		>
			<TextField
				placeholder="Search"
				InputProps={{
					startAdornment: (
						<InputAdornment
							position="start"
							sx={{
								color: "white",
								display: { xs: "none", sm: "flex" },
							}}
						>
							<SearchIcon />
						</InputAdornment>
					),
				}}
				value={inputValue}
				multiline={false}
				autoFocus
				sx={{
					background: "transparent",
					borderRadius: 3,
					width: "inherit",
					"& .MuiInputBase-input": {
						paddingTop: "4px",
						paddingBottom: "4px",
						color: "white",
						fontWeight: 500,
					},
					"& .MuiOutlinedInput-notchedOutline": {
						borderColor: "#fe0039",
					},
				}}
				onChange={(event) => onTextChange(event.target.value)}
			/>
		</Box>
	);
});

import { Box, ImageList, Skeleton, Stack } from "@mui/material";
import { memo, useContext } from "react";
import { WindowWidthContext } from "../../Context/WindowWidth.context";

export const ImageAndTitleCardSkeleton = memo(
	({ blocks, padding }: { blocks: number; padding?: string }) => {
		const { windowWidth } = useContext(WindowWidthContext);

		return (
			<Box
				className="image-and-title-card-loader"
				sx={{
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					display: "flex",
					paddingInline: padding ?? {
						xs: "20px",
						sm: "30px",
						md: "60px",
					},
					width: {
						Webkit: "-webkit-fill-available",
						Moz: "-moz-available",
						width: "fill-available",
					},
				}}
			>
				<ImageList
					sx={{
						width: "100%",
						overflow: "hidden",
						gap: `2.5rem ${
							windowWidth <= 600 ? "10px" : "20px"
						} !important`,
						minHeight: "300px",
					}}
					cols={blocks}
				>
					{Array.from({ length: blocks * 2 }, (_, index) => (
						<Stack key={index} direction="column">
							<Skeleton
								key={`${index}-poster`}
								variant="rectangular"
								animation="wave"
								height={300}
								sx={{
									"&::after": {
										background: `linear-gradient( 31deg, transparent, rgba(255, 255, 255, 0.22), transparent )`,
									},
								}}
							/>
							<Skeleton
								key={`${index}-text`}
								variant="text"
								animation="wave"
								height={70}
								sx={{
									"&::after": {
										background: `linear-gradient( 31deg, transparent, rgba(255, 255, 255, 0.22), transparent )`,
									},
								}}
							/>
						</Stack>
					))}
				</ImageList>
			</Box>
		);
	}
);

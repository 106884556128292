import useWatchedShows from "../../hooks/useWatchedShows";
import { SuggestionSwiper } from "./SuggestionSwiper";

export const ContinueWatchingSwiper = () => {
	const { getWatchedShows } = useWatchedShows();
	const watchedShows = getWatchedShows();

	const breakpoints = {
		240: {
			slidesPerView: 2,
		},
		900: {
			slidesPerView: 3,
		},
		1540: {
			slidesPerView: 4,
		},
		2500: {
			slidesPerView: 5,
		},
	};

	return watchedShows.length ? (
		<SuggestionSwiper
			headingName="Continue watching..."
			showsList={watchedShows}
			isUpdate={true}
			overrideSwiperProps={{ breakpoints }}
		/>
	) : null;
};

export const getColumnsCount = (windowWidth: number) => {
	if (windowWidth < 500) {
		return 2;
	} else if (windowWidth >= 500 && windowWidth < 900) {
		return 3;
	} else if (windowWidth >= 900 && windowWidth < 1240) {
		return 4;
	} else if (windowWidth >= 1240 && windowWidth < 1540) {
		return 5;
	} else if (windowWidth >= 1540 && windowWidth < 2400) {
		return 6;
	} else if (windowWidth >= 2400) {
		return 7;
	} else {
		return 8;
	}
};

export const skeletonWaveStyle = {
	"&::after": {
		background: `linear-gradient( 31deg, transparent, rgba(255, 255, 255, 0.22), transparent )`,
	},
};

import { Alert, Box } from "@mui/material";
import { memo, useContext } from "react";
import { SuggestionSwiperContext } from "../../Context/SuggestionSwiper.context";
import { WindowWidthContext } from "../../Context/WindowWidth.context";
import { getColumnsCount } from "../../utils/utils";
import { ImageAndTitleCardSkeleton } from "../LoadingSkeletons/ImageAndTitleCardSkeleton";
import { ContinueWatchingSwiper } from "./ContinueWatchingSwiper";
import { SuggestionSwiper } from "./SuggestionSwiper";

export const genreToHeadingMap: Record<string, string[]> = {
	Update: [
		"Hot off the press!",
		"Freshly baked content!",
		"Catch up or miss out!",
	],
	Action: ["Boom! Pow! Zing!", "Action overload!", "Get ready to rumble!"],
	"Adult Cast": ["All grown up!", "Not for the kiddos!", "Mature and sassy!"],
	Adventure: ["Medieval energy!", "Get ready to explore!"],
	Anthropomorphic: [
		"Animals with attitude!",
		"Furry fun times!",
		"Not your average pets!",
	],
	Cars: ["Need for speed!", "Vroom vroom!", "Car lovers unite!"],
	CGDCT: [
		"Cutie overload!",
		"Girls being adorable!",
		"Cuteness personified!",
	],
	Childcare: [
		"Tiny tots and big hearts!",
		"Baby love!",
		"Caring for the little ones!",
	],
	Comedy: ["Laugh till you drop!", "Comedy chaos!", "Jokes on jokes!"],
	Comic: [
		"Panel popping fun!",
		"Comic book madness!",
		"Illustrated giggles!",
	],
	Crossdressing: ["Dress up time!", "Fashion flips!", "Cross-dressing fun!"],
	Delinquents: [
		"Rebel rousers!",
		"Trouble with a capital T!",
		"Bad boys and girls!",
	],
	Dementia: [
		"Mind-bending craziness!",
		"Lost in the mind!",
		"Reality is overrated!",
	],
	Demons: ["Hellish fun!", "Demon days!", "Spooky and sinister!"],
	Detective: ["Sherlock who?", "Case closed!", "Mystery-solving mode!"],
	Drama: ["Tears and cheers!", "Drama central!", "Emotions on high!"],
	Dub: ["Voice-over bonanza!", "Dubbing delight!", "Get Duolingo!"],
	Ecchi: ["Cheeky and saucy!", "Playful naughtiness!", "Mildly spicy!"],
	Family: ["Warm and fuzzy!", "Nostalgic feels!", "Heartwarming moments!"],
	Fantasy: ["Magic and wonder!", "Fantasy land awaits!", "Epic adventures!"],
	Game: ["Level up!", "Gaming galore!", "Pixelated fun!"],
	Gore: ["Bloody and gory!", "Gruesome thrills!", "Shock and awe!"],
	Gourmet: ["Feast your eyes!", "Foodie fantasy!", "Deliciously good!"],
	Harem: [
		"Love triangle chaos!",
		"Multiple love interests!",
		"Romantic overload!",
	],
	"High Stakes Game": ["High tension!", "Game on the edge!", "Risk it all!"],
	Historical: [
		"Blast from the past!",
		"History comes alive!",
		"Time travel thrills!",
	],
	Horror: ["Scary and spooky!", "Sleep with the lights on!", "Horror fest!"],
	Isekai: [
		"New worlds await!",
		"Fantasy escapes!",
		"Another world adventure!",
	],
	Iyashikei: ["Healing vibes!", "Soothing and calming!", "Peaceful retreat!"],
	Josei: ["Mature romance!", "Real life love!", "Adult stories!"],
	Kids: [
		"Fun for the young!",
		"Kid-friendly antics!",
		"Playtime adventures!",
	],
	"Love Polygon": [
		"Love square and more!",
		"Romantic entanglements!",
		"Heartfelt drama!",
	],
	Magic: ["Spellbinding action!", "Magical mayhem!", "Enchanting escapades!"],
	"Mahou Shoujo": [
		"Magical girl power!",
		"Heroic and cute!",
		"Sparkly adventures!",
	],
	"Martial Arts": [
		"Kung fu kicks!",
		"Martial mastery!",
		"Fight scenes galore!",
	],
	Mecha: ["Robots rule!", "Mecha mayhem!", "Giant robot battles!"],
	Medical: ["Doctor drama!", "Medical mysteries!", "Healthcare high stakes!"],
	Military: ["Warriors and strategy!", "Military might!", "Combat action!"],
	Music: ["Musical magic!", "Tune into fun!", "Rhythmic adventures!"],
	Mystery: ["Clues and twists!", "Mystery mayhem!", "Solve the puzzle!"],
	Mythology: ["Gods and legends!", "Mythical adventures!", "Epic tales!"],
	Parody: ["Spoof-tastic!", "Comic exaggerations!", "Parody party!"],
	Pets: ["Cute and cuddly!", "Pet fun!", "Animal antics!"],
	Police: ["Law and order!", "Crime fighters!", "Police action!"],
	Psychological: [
		"Mind games!",
		"Psychological twists!",
		"Mental gymnastics!",
	],
	Racing: ["Speed demons!", "Race to win!", "Fast and furious!"],
	Reincarnation: [
		"New life adventures!",
		"Past lives and more!",
		"Rebirth tales!",
	],
	Romance: [
		"Love is in the air!",
		"Heartfelt romance!",
		"Passionate moments!",
	],
	Samurai: ["Warrior code!", "Samurai spirit!", "Epic sword fights!"],
	School: ["Classroom fun!", "School life antics!", "Student shenanigans!"],
	"Sci-Fi": ["Future shock!", "Sci-fi thrills!", "Tech and space!"],
	Seinen: ["Mature themes!", "Adult stories!", "Complex narratives!"],
	Shoujo: ["Girl power!", "Romantic and emotional!", "Shoujo vibes!"],
	Shounen: ["Heroic boys!", "Action-packed!", "Young and fearless!"],
	Showbiz: ["Star-studded!", "Entertainment industry!", "Showbiz drama!"],
	"Slice of Life": [
		"Everyday fun!",
		"Life’s little moments!",
		"Slice of life goodness!",
	],
	Space: ["Cosmic adventures!", "Galactic escapades!", "Starry thrills!"],
	Sports: ["Game on!", "Athletic action!", "Sporting drama!"],
	"Strategy Game": [
		"Tactical play!",
		"Strategic brilliance!",
		"Game of wits!",
	],
	"Super Power": ["Super abilities!", "Heroic feats!", "Power-up time!"],
	Supernatural: [
		"Otherworldly thrills!",
		"Supernatural wonders!",
		"Unseen forces!",
	],
	Survival: ["Struggle to survive!", "Life or death!", "Survival instincts!"],
	Suspense: [
		"Tense and thrilling!",
		"Edge-of-your-seat!",
		"Suspenseful moments!",
	],
	"Team Sports": [
		"Teamwork triumphs!",
		"Sports and strategy!",
		"Group games!",
	],
	Thriller: [
		"Heart-pounding action!",
		"Thriller thrills!",
		"Adrenaline rush!",
	],
	"Time Travel": [
		"Temporal escapades!",
		"Journey through time!",
		"Past and future!",
	],
	Vampire: ["Fangs and blood!", "Immortal thrills!", "Gothic horror!"],
	"Video Game": ["Gaming adventures!", "Pixel fun!", "Virtual quests!"],
	"Visual Arts": [
		"Artistic brilliance!",
		"Visual wonders!",
		"Art in motion!",
	],
	"Work Life": ["Office drama!", "Career challenges!", "Workplace antics!"],
	Workplace: ["Daily grind!", "Office shenanigans!", "Work-life drama!"],
	Yaoi: ["Boys’ love!", "Romantic and steamy!", "Heartfelt connections!"],
	Yuri: ["Girls’ love!", "Sweet and romantic!", "Tender relationships!"],
};

export const SuggestionContainer = memo(() => {
	const { data, isError, isLoading } = useContext(
		SuggestionSwiperContext
	).state;
	const { windowWidth } = useContext(WindowWidthContext);

	let content;

	if (isLoading) {
		content = (
			<ImageAndTitleCardSkeleton blocks={getColumnsCount(windowWidth)} />
		);
	}

	if (isError) {
		content = <Alert severity="error">Something went wrong!!</Alert>;
	}

	if (data) {
		const genres = Object.keys(data.data);
		content = (
			<>
				<ContinueWatchingSwiper key="watching now" />
				{genres.map((genre) => {
					const heading = genreToHeadingMap[genre]
						? genreToHeadingMap[genre][
								Math.floor(
									Math.random() *
										genreToHeadingMap[genre].length
								)
						  ]
						: genre;
					return (
						<SuggestionSwiper
							key={genre}
							headingName={heading}
							showsList={data.data[genre]}
							isUpdate={genre === "Update"}
						/>
					);
				})}
			</>
		);
	}

	return (
		<Box
			className="suggestion-container"
			sx={{
				marginTop: { xs: "10vi", sm: "1vi", md: "-5vi" },
				zIndex: 5,
				position: "relative",
				display: "grid",
				rowGap: "2.5rem",
				gridTemplateColumns: "minmax(0,auto)",
			}}
		>
			{content}
		</Box>
	);
});
